import React, { useRef, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  styled,
  IconButton,
  Dialog,
} from "@mui/material";
import "../../styles/AddCourse.css";
import Uplaod from "../../assets/Courses/upload-icon.png";
import { uploadFileService } from "../../services/Request";
import { postData, getData } from "../../services/Request";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoader } from "../../context/LoaderContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";

const rowsPerPageOptions = [10, 20];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 20, // Set your desired font size
  backgroundColor: "#61ED66", // Set your desired background color
  color: theme.palette.common.white, // Set your desired text color
}));

const AddTestimonial = () => {
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoder] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [userData, setUserData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("add"); // 'add' or 'edit'
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  useEffect(() => {
    getAllTestimonials();
  }, []);

  const getAllTestimonials = () => {
    showLoader();
    getData("getAllTestimonials")
      .then((response) => {
        setUserData(response.testimonials);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  };
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    if (file.size / 1024 / 1024 > 1) {
      alert("File size exceeds 1 MB");
    } else {
      uploadFileService(file).then((response) => {
        console.log(response);
        if (response.status === true) {
          setImageUrl(response.location);
        } else {
          alert("Error upload picture");
        }
      });
    }
  };
  const handleSubmit = (e) => {
    setLoder(true);
    e.preventDefault();
    var details = {
      name: e.target.name.value,
      title: e.target.title.value,
      description: e.target.description.value,
      image: imageUrl,
    };

    postData("addTestimonial", details)
      .then((response) => {
        if (response.status === true) {
          setOpenModal(false);
          setTimeout(function () {
            getAllTestimonials();
          }, 500);
        } else {
          alert("Error adding course ");
        }
      })
      .catch((error) => {
        setLoder(false);
        alert("Error adding course , please check your account is activated");
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedData = userData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleOpenModal = (type, data = null) => {
    setModalType(type);
    setSelectedTestimonial(data);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedTestimonial(null);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Do you want to delete this Testimonial?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        postData("deleteTestimonial", {
          id: id,
        });
        setTimeout(function () {
          getAllTestimonials();
        }, 2000);
      } else if (result.isDenied) {
        Swal.fire("Testimonial not delete", "", "info");
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal("add")}
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        Add Testimonial
      </Button>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedData.map((row, index) => (
                <TableRow key={row.id} hover>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <img
                      src={row.image}
                      alt="img"
                      style={{ height: "50px", width: "50px" }}
                    ></img>
                  </TableCell>
                  <TableCell> {row.name}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenModal("edit", row)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <Grid container>
          <Grid xs={12} ml={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <h2>
                    {modalType === "add"
                      ? "Add Testimonial"
                      : "Edit Testimonial"}
                  </h2>
                </Grid>

                <Grid item xs={11}>
                  <div className="upload-box" onClick={handleUploadClick}>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <div>
                      <img src={Uplaod}></img>
                    </div>
                    <div>
                      <span className="add-upload">Click to upload</span>
                      <spna className="add-drag">or drag and drop</spna>
                    </div>
                    <div className="add-file">
                      PNG, JPG or GIF (max. 800x400px)
                    </div>

                    {imageUrl && (
                      <div>
                        <img
                          src={imageUrl}
                          alt="Selected"
                          style={{ maxWidth: "250px" }}
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    color="success"
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    label="Name"
                    type="text"
                    id="name"
                    defaultValue={selectedTestimonial?.name || ""}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    color="success"
                    margin="normal"
                    required
                    fullWidth
                    name="title"
                    label="Title"
                    type="text"
                    id="title"
                    defaultValue={selectedTestimonial?.title || ""}
                  />
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    color="success"
                    required
                    fullWidth
                    name="description"
                    label="Description"
                    placeholder="Give a brief description"
                    type="text"
                    id="description"
                    multiline
                    rows={3}
                    defaultValue={selectedTestimonial?.description || ""}
                  />
                </Grid>

                <Grid
                  item
                  xs={11}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end", // Aligns items to the right
                    gap: "10px", // Adds spacing between buttons
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                    sx={{ mb: 2 }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{ mb: 2 }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AddTestimonial;
