import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoaderProvider } from "./context/LoaderContext";
import Landingpage from "./pages/LandingPage";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import SignUp from "./pages/student/UserSignUp";
import LogIn from "./pages/student/UserLogin";
import CoursePreview from "./pages/instructor/CoursePreview";
import InstructorLogIn from "./pages/instructor/InstructorLogin";
import InstructorSignUp from "./pages/instructor/InstructorSignUp";
import InstructorCourses from "./pages/instructor/InstructorCourses";
import AdminLogIn from "./pages/admin/AdminLogin";
import Courses from "./pages/student/Courses";
import MyCourses from "./pages/student/MyCourses";
import CourseDetail from "./pages/student/CourseDetail";
import AddCourse from "./pages/instructor/AddCourse";
import Test from "./pages/test";
import Account from "./pages/instructor/Account";
import Setting from "./pages/student/StudentSetting";
import Certificates from "./pages/student/Certificates";
import StudentsTables from "./pages/admin/StudentsTable";
import InstructorTable from "./pages/admin/InstructorTable";
import TeacherSetting from "./pages/instructor/TeacherSetting";
import TeacherCall from "./pages/instructor/components/TeacherCall";
import Loader from "./components/Loader";
import SideBar from "./pages/admin/components/SideBar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddTestimonial from "./pages/admin/AddTestimonial";

function App() {
  return (
    <LoaderProvider>
      <div className="App">
        <Loader />
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />

          {/* Admin  */}
          <Route path="/admin" element={<AdminLogIn />} />
          <Route
            path="/studentTable"
            element={<LayoutWithSidebar page={<StudentsTables />} />}
          />
          <Route
            path="/InstructorTable"
            element={<LayoutWithSidebar page={<InstructorTable />} />}
          />
          <Route
            path="/addTestimonial"
            element={<LayoutWithSidebar page={<AddTestimonial />} />}
          />

          {/* Student New Pages */}
          <Route path="/usersignup" element={<SignUp />} />
          <Route path="/userlogin" element={<LogIn />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/mycourses" element={<MyCourses />} />
          <Route path="/coursedetail" element={<CourseDetail />} />
          <Route path="/studentsetting" element={<Setting />} />

          {/* Instructor New Pages */}
          <Route path="/teachersignup" element={<InstructorSignUp />} />
          <Route path="/teacherlogin" element={<InstructorLogIn />} />
          <Route path="/addcourse" element={<AddCourse />} />
          <Route path="/coursepreview" element={<CoursePreview />} />
          <Route path="/instcourses" element={<InstructorCourses />} />
          <Route path="/account" element={<Account />} />
          <Route path="/teachersetting" element={<TeacherSetting />} />
          <Route path="/teachecall" element={<TeacherCall />} />

          {/* Test */}
          <Route path="/home" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/Certificates" element={<Certificates />} />
        </Routes>
      </div>
    </LoaderProvider>
  );
}

const drawerWidth = 240;
// Layout with Sidebar for protected pages
const LayoutWithSidebar = ({ page }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <SideBar />
      {/* Main content area should have a margin-left equal to the sidebar's width */}
      <div
        style={{
          padding: "2px",
          marginTop: "-49px",
          marginLeft: isMobile ? 0 : `${drawerWidth}px`,
        }}
      >
        {page}
      </div>
    </div>
  );
};
export default App;
